import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box, Center, Divider, Icon, Text, useToast } from '@chakra-ui/react';
import { Info, Upload } from 'phosphor-react';

import { useSendEmail } from '@hooks';
import { BaseHeading, BaseScene, BaseSeo } from '@components/base';
import { TapeSubmitForm } from '@components/tape';

export default function SubmitPage() {
  const { sendSubmitEmail } = useSendEmail();
  const toast = useToast();
  const [isUploading, setIsUploading] = React.useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = React.useCallback(
    async (data) => {
      setIsSubmitting(true);

      if (data.files && data.files.length) {
        const [file] = data.files;
        // convert to Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          // extract only the file data part and prepare info to send to API
          const rawLog = reader.result.split(',')[1];
          const dataSend = {
            dataReq: { data: rawLog, name: file.name, type: file.type },
            fname: 'uploadFilesToGoogleDrive',
          };
          try {
            setIsUploading(true);

            const res = await fetch(process.env.GATSBY_GDRIVE_UPLOAD_SCRIPT, {
              method: 'POST',
              body: JSON.stringify(dataSend),
            });

            const jsonRes = await res.json();

            setIsUploading(false);
            const isSuccess = await sendSubmitEmail({
              site: process.env.GATSBY_SITEMETA_TITLE,
              title: data.title,
              description: data.description,
              uploadedFileUrl: jsonRes.url,
              fileUrlLink: data.fileUrlLink,
            });

            setIsSubmitting(false);
            setIsSubmitSuccessful(isSuccess);
            toast({
              title: isSuccess ? 'File Submitted' : 'Sending Failed',
              status: isSuccess ? 'success' : 'error',
              duration: 4000,
              isClosable: true,
            });
          } catch (error) {
            setIsUploading(false);
            setIsSubmitting(false);
            setIsSubmitSuccessful(false);
            toast({
              title: 'Sending Failed',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
        };
      } else {
        const isSuccess = await sendSubmitEmail({
          site: process.env.GATSBY_SITEMETA_TITLE,
          title: data.title,
          description: data.description,
          fileUrlLink: data.fileUrlLink,
        });

        setIsSubmitting(false);
        setIsSubmitSuccessful(isSuccess);
        toast({
          title: isSuccess ? 'File Submitted' : 'Sending Failed',
          status: isSuccess ? 'success' : 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    },
    [toast, sendSubmitEmail]
  );

  return (
    <BaseScene as='section'>
      <Box marginInline='auto' w={{ base: 'full', sm: 'md' }}>
        <Box pb={4}>
          <BaseHeading as='h2' icon={Upload}>
            Submit Video
          </BaseHeading>
          <Divider pt={1} />
        </Box>
        <TapeSubmitForm
          isUploading={isUploading}
          isSubmitSuccessful={isSubmitSuccessful}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          onReset={() => setIsSubmitSuccessful(false)}
        />
        <Center
          p={4}
          bgColor='blue.600'
          borderRadius='lg'
          transform={`scale(${isUploading ? 1 : 0})`}
          transition='0.3s ease transform 0.8s'
        >
          <Icon as={Info} mr={2} w={8} h={8} />
          <Text as='small'>
            Depending on the size of the file and on your upload speed,
            submitting a file may take seconds to a few minutes to complete.
            Please wait...
          </Text>
        </Center>
      </Box>
    </BaseScene>
  );
}

export function Head({ data }) {
  const { pathname } = useLocation();
  const { siteUrl, siteTitle, datePublished } = data.site.siteMetadata;

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `Submit Video ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified: datePublished,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, datePublished]);

  return (
    <BaseSeo
      title='Submit Video'
      description='Submit a Video'
      schemaGraphs={schemaGraphs}
    />
  );
}

export const query = graphql`
  query submitQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        datePublished
      }
    }
  }
`;
